<template>
  <b-card title="Yorum Ekle">
    <b-form-group>
      <b-form-textarea
        v-model="startupComment.content"
        placeholder="Yorum Ekle"
      />
    </b-form-group>
    <b-row>
      <b-col>
        <b-button
          variant="success"
          size="sm"
          @click="submitComment"
        >
          <FeatherIcon icon="SaveIcon" />
          Kaydet
        </b-button>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="startupComment.id"
          variant="danger"
          size="sm"
          @click="removeComment"
        >
          <FeatherIcon icon="XIcon" />
          Yorumu Sil
        </b-button>
      </b-col>
    </b-row>
    <div
      v-if="startupComment.id"
      class="mt-2"
    >
      <div
        class="text-muted text-italic match-height"
      >
        <div v-if="startupComment.modified">
          {{ moment(startupComment.modified).format('LLLL') }} tarihinde {{ startupComment.username }} tarafından güncellendi.
        </div>
        <div v-else>
          {{ moment(startupComment.created).format('LLLL') }} tarihinde {{ startupComment.username }} tarafından eklendi.
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BFormGroup, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Comment',
  components: {
    BCard,
    BFormGroup,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
  },
  computed: {
    startupComment() {
      return this.$store.getters['startupComments/dataItem']
    },
    startupSaveStatus() {
      return this.$store.getters['startupComments/dataSaveStatus']
    },
  },
  watch: {
    startupSaveStatus(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
          content: null,
          id_faq_categories: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getStartupComment()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getStartupComment()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getStartupComment() {
      this.$store.dispatch('startupComments/getData', this.$route.params.id)
    },
    submitComment() {
      this.startupComment.id_startups = this.$route.params.id
      this.$store.dispatch('startupComments/dataSave', this.startupComment)
    },
    removeComment() {
      this.$store.dispatch('startupComments/dataRemove', this.startupComment.id)
    },
  },
}
</script>

<style scoped>

</style>
