<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th>{{ subtitle2 }}</b-th>
          <b-th class="text-right">
            TUTAR
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>Kira Komisyonu - Depozito</b-td>
          <b-td class="text-right">
            {{ tableData.kira_komisyonu_depozito | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Makine ve Teçhizat Giderleri</b-td>
          <b-td class="text-right">
            {{ tableData.makine_techizat_giderleri | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Lisans, İzin, Ruhat vb</b-td>
          <b-td class="text-right">
            {{ tableData.lisans_izin_ruhsat | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Kuruluş İşlemleri ve Harç Masrafları</b-td>
          <b-td class="text-right">
            {{ tableData.kurulus_islemleri | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Genel Giderler</b-td>
          <b-td class="text-right">
            {{ tableData.genel_giderler | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Beklenmeyen Giderler</b-td>
          <b-td class="text-right">
            {{ tableData.beklenmeyen_giderler | toCurrency }} ₺
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th class="align-middle">
            Toplam
          </b-th>
          <b-th class="text-right">
            {{ tableData.toplam_sabit_yatirim | toCurrency }} ₺
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

export default {
  name: 'PlanTable1',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
  },
  props: {
    subtitle2: {
      type: String,
      required: false,
      default: null,
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
}
</script>
