<template>
  <div>
    <comment />
    <div
      v-for="item in financialPlanning"
      :key="item.id"
    >
      <b-card :title="item.title">
        <b-card-sub-title class="mb-1">
          {{ item.subtitle }}
        </b-card-sub-title>
        <plan-table1
          v-if="item.id === '1'"
          :subtitle2="item.subtitle2"
          :table-data="item.items"
        />
        <plan-table2
          v-if="item.id === '2'"
          :subtitle2="item.subtitle2"
          :table-data="item.items"
        />
        <plan-table3
          v-if="item.id === '3'"
          :subtitle2="item.subtitle2"
          :table-data="item.items"
        />
        <plan-table4
          v-if="item.id === '4'"
          :subtitle2="item.title"
          :table-data="item.items"
        />
        <plan-table5
          v-if="item.id === '5'"
          :subtitle2="item.subtitle2"
          :table-data="item.items"
        />
        <plan-table6
          v-if="item.id === '6'"
          :subtitle2="item.subtitle2"
          :table-data="item.items"
        />
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard, BCardSubTitle,
} from 'bootstrap-vue'
import PlanTable1 from '@/views/Admin/Startup_applies/FinancialPlanning/PlanTable1.vue'
import PlanTable2 from '@/views/Admin/Startup_applies/FinancialPlanning/PlanTable2.vue'
import PlanTable3 from '@/views/Admin/Startup_applies/FinancialPlanning/PlanTable3.vue'
import PlanTable4 from '@/views/Admin/Startup_applies/FinancialPlanning/PlanTable4.vue'
import PlanTable5 from '@/views/Admin/Startup_applies/FinancialPlanning/PlanTable5.vue'
import PlanTable6 from '@/views/Admin/Startup_applies/FinancialPlanning/PlanTable6.vue'
import Comment from '@/views/Admin/Startup_applies/FinancialPlanning/Comment.vue'

export default {
  name: 'FinancialPlanning',
  components: {
    BCard,
    BCardSubTitle,
    PlanTable1,
    PlanTable2,
    PlanTable3,
    PlanTable4,
    PlanTable5,
    PlanTable6,
    Comment,
  },
  computed: {
    financialPlanning() {
      return this.$store.getters['financialPlanning/getFinancialPlanning']
    },
  },
  created() {
    this.getFinancialPlanning()
  },
  methods: {
    getFinancialPlanning() {
      this.$store.dispatch('financialPlanning/financialPlanning', this.$route.params.id)
    },
  },
}
</script>

<style scoped>

</style>
